.cw-side-bar {
    border-right: 1px solid #ddd;
    border-bottom: 1px solid #ddd;
}

.custom-blue-button {
    background-color: #DBEDFF !important;
    color: #3699FF !important;
}

.custom-blue-button-dashboard {
    background-color: #88C3FF !important;
    color: #FFFFFF !important;
}

.custom-green-button {
    background-color: #1BC5BD !important;
    color: white !important;
}

.custom-blue-text {
    color: #3699FF !important;
}

.custom-cancel-button {
    background-color: #EEF0F8 !important;
    color: black;
}

.no-padding-bottom {
    padding-bottom: 0px !important;
}

.custom-gray-button {
    background-color: #F3F6F9 !important;
    color: #3F4254 !important;
    border: 0 !important;
    font-weight: 500 !important;
    text-transform: capitalize;
}

.MuiPaper-elevation1 {
    box-shadow: none !important;
}

.mtb-10 {
    margin-top: 10px !important;
    margin-bottom: 10px !important;
}

.form-bottom-divider {
    width: 109%;
    margin-left: -26px !important;
    margin-bottom: 20px !important;
    margin-top: 20px !important;
}

.fs-1 {
    font-size: 1rem !important;
}

.h-0 {
    height: 0;
}

.w-0 {
    width: 0;
}

.avatar-container {
    vertical-align: middle;
    position: relative;
    border-radius: 50% !important;

    .avatar {
        left: 50%;
        position: absolute;
        top: 50%;
        transform: translate(-50%, -50%);
    }
}

.editorClassName {
    height: 30vh !important;
    overflow: scroll;
    resize: both;
}

.custom-tb-cell {
    font-size: 13px !important;
    color: #3F4254 !important;
}

.timeline-desc {
    font-size: 0.875rem !important;
}

.custom-header {
    font-size: 16px !important;
}

.with-files {
    fill: #1BC5BD !important;
}

.current-year {
    color: #3699ff !important;
}

.current-date {
    color: #1bc5bd !important;
}

.svg-icon.svg-icon-green svg g [fill] {
    fill: #1bc5bd !important;
}

.svg-icon.svg-icon-blue svg g [fill] {
    fill: #3699FF !important;
}

.svg-icon.svg-icon-red svg g [fill] {
    fill: #FF99A7 !important;
}

.icon-green {
    color: #1bc5bd !important;
}

.has-file {
    color: #c9f7f5 !important;
}

.custom-border .MuiOutlinedInput-root {
    border: 1px solid #ced4da; /* Default border */
    transition: border-color 0.3s ease-in-out;
}

.custom-border .MuiOutlinedInput-root.Mui-focused {
    border-color: #17c191 !important; /* Change border color on focus */
}
.custom-border {
    width: 100%;
    padding: 2px 0 !important; 
}
@media print {
    * {
        -webkit-print-color-adjust: exact !important;
        print-color-adjust: exact !important;
    }

    body * {
        height: auto;
        width: auto;
    }

    #kt_header {
        visibility: hidden !important;
    }

    #kt_subheader {
        visibility: hidden !important;
    }

    .subheader {
        display: none !important;
    }

    .col-12.p-6.bg-white.border-0.d-flex.mb-7.align-items-center.rounded {
        display: none !important;
    }

    .header-mobile.align-items-center.header-mobile-fixed {
        display: none !important;
    }

    .fab-print {
        display: none !important;
    }

    .timeline.border-0 {
        height: auto !important;
        overflow-y: auto !important;
    }

    .footer {
        display: none !important;
    }

    .card-toolbar.d-flex.justify-space-around {
        display: none !important;
    }

    .MuiTimelineConnector-root {
        width: 2px !important;
        flex-grow: 1 !important;
        background-color: #bdbdbd !important;
    }

    .MuiPaper-root.timeline-paper.MuiPaper-elevation0.MuiPaper-rounded {
        background: #F3F6F9 !important;
        border-radius: 8px !important;
        min-height: 150px !important;
        padding: 15px 15px 0 15px !important;
        border: solid black 3px !important;
    }

    .quick-panel-right.demo-panel-right.offcanvas-right.header-fixed.header-mobile-fixed.subheader-enabled.subheader-fixed.aside-enabled.aside-fixed.aside-minimize-hoverable.brand-dark {
        background-color: white !important;
    }

    .MuiButtonBase-root.MuiIconButton-root.MuiIconButton-sizeSmall {
        display: none !important;
    }

    .MuiButtonBase-root.MuiIconButton-root {
        display: none !important;
    }

    .MuiTypography-root.text-left.MuiTypography-h6 {
        font-size: 1.8rem !important;
    }

    .MuiTypography-root.mt-5.text-left.MuiTypography-body1 {
        font-size: 1.2rem !important;
    }
}